









import { Component, Vue } from 'vue-property-decorator';
import { ItemCyclerImageSlide, ItemCyclerSlide } from '@/app_code/ItemCycler';
import { CarouselBreakpoint } from '@/app_code/CarouselBreakpoint';
import ItemCycler from '@/components/Cycler/ItemCycler.vue';

@Component({
  components: {
    ItemCycler,
  },
})
export default class Featured extends Vue {
  public featuredCyclerItems: ItemCyclerSlide[] = [
    new ItemCyclerImageSlide({ source: '/assets/images/featured/RateMyAgent', formats: ['png'], fallback: 'png' }),
    new ItemCyclerImageSlide({ source: '/assets/images/featured/Google', formats: ['png'], fallback: 'png' }),
    new ItemCyclerImageSlide({ source: '/assets/images/featured/Airbnb', formats: ['png'], fallback: 'png' }),
    new ItemCyclerImageSlide({ source: '/assets/images/featured/Facebook', formats: ['png'], fallback: 'png' }),
    new ItemCyclerImageSlide({ source: '/assets/images/featured/Booking', formats: ['png'], fallback: 'png' }),
  ];

  public breakpoints: CarouselBreakpoint[] = [
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1120,
      settings: {
        slidesToShow: 4,
      },
    },
  ];
}
